import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { FaArrowAltCircleDown } from "react-icons/fa"
import { FcFilledFilter, FcShop } from "react-icons/fc"
import { MdOutlinePriceChange, MdOutlineSettingsSuggest } from "react-icons/md"
import { HiOutlineDocumentReport } from "react-icons/hi"
import { SiMinutemailer } from "react-icons/si"
import { GiMaterialsScience } from "react-icons/gi"
const ecommerce = () => {
  return (
    <Layout>
      <Seo
        title="Personalized e-commerce Development"
        description="We provide ecommerce web design and web development services which utilize latest technology to maximize profitability of your online store."
        keyword="e-commerce"
      />
      <Container fluid className="min-vh-100 position-relative">
        <br />
        <br />
        <br />
        <br />
        <Row className="mt-3">
          <Col
            sm={12}
            className="d-block d-md-none align-items-center text-center my-auto"
          >
            <h1 className="text3d fs-3 mb-5">E-COMMERCE</h1>
          </Col>
          <Col md={6} sm={12}>
            <StaticImage
              alt="e-commerce poster"
              src={"../images/ecommerce.jpg"}
              className="ms-auto my-auto"
              placeholder={"tracedSVG"}
              layout={"constrained"}
              formats={["auto", "webp", "avif"]}
            />
          </Col>
          <Col className="d-none d-md-block align-items-center text-center my-auto">
            <h1 className="text3d fs-3 mb-5">E-COMMERCE</h1>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <Row className="text-center text-white">
          <Col>
            <h2 className="h2 luminance">
              Unique Solutions Which Differentiates From Crowd!
            </h2>
          </Col>
        </Row>
        <br />
        <div className="position-absolute bottom-0 start-50 text-white mb-1">
          <FaArrowAltCircleDown className="bouncearrow" />
        </div>
      </Container>
      <Container fluid className="bg-white">
        <br />
        <br />
        <br />
        <br />
        <Container className="align-items-center fs-5 text-center">
          <p
            className="bg-text fs-5 text-wrap"
            data-bg-text="E-COMMERCE"
            data-aos="zoom-in-right"
          >
            <br />
            Your website is more than just a collection of brochures. Your
            website is intended to encourage people to take action. Our websites
            can provide clear path to this activity for the user (the buying
            process). Useful information is clear to comprehend and appealing,
            and powerful calls to action propel customers along in the path to
            purchase. With our e-commerce website development skills, you'll
            notice more completed transactions, better average order value, and
            greater ROI for your online store thanks to clean and intuitive
            UI/UX design, mobile compatibility, and quick page load rates.
          </p>
          <br />
          <br />
          <b>
            Deliver an online experience that exceeds user expectations while
            still generating tangible commercial outcomes.
          </b>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid>
        <br />
        <br />
        <h2
          className="text-center bg-textlight"
          data-aos="flip-left"
        >
          OUR STRATEGY
        </h2>
        <br />
        <br />
        <Container className="text-white text-wrap text-center fs-5">
          <Row>
            <Col sm={12} md>
              <b>Case Study</b>
              <br />
              <br />
              <div>
                Form follows function at TheSoftCode. That means we construct
                appealing, eye-catching websites, but the process starts with a
                thorough assessment of your business objectives and a deliberate
                approach to achieving them. See how our results-driven approach
                to innovative and adaptive design helps our customers achieve
                the desired business goals.
              </div>
            </Col>
            <Col sm={12} md>
              <b>What It Takes To Succeed</b>
              <br />
              <br />
              <div>
                We concentrate on functionality, features, and appearance while
                developing e-commerce websites. Your online store will have a
                beautiful, unique web presentation, but you'll also need the
                typical features of today's popular online stores and shopping
                cart platforms to meet your e-commerce sales objectives. To
                administer your website as a company owner, you'll need tools
                that meet your business operations and administrative functions.
                To understand more about these tools, keep reading.
              </div>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid className="bg-white">
        <br />
        <br />
        <h2 className="text-center bg-textdark" data-aos="flip-up">
          KEY TO CONVERSION
        </h2>
        <br />
        <br />
        <Container className="fs-5 text-wrap text-center">
          The most important part and crucial determinant for your online
          success is a properly planned and produced e-commerce site design.
          Customers must be wowed by your website in order to stay engaged. That
          is how you close the deal. Your company's e-commerce site's design and
          development must be well-organized, user-friendly, and functional. 
        </Container>
        <br />
        <br />
        <br />
      </Container>
      <Container fluid>
        <br />
        <br />
        <h2
          className="text-center bg-textlight"
          data-aos="flip-left"
        >
          KEY ELEMENTS
        </h2>
        <br />
        <br />
        <Container className="text-wrap fs-5 text-center">
          <Row>
            <Col
              sm={12}
              md={4}
              className="text-center text-warning"
              data-aos="zoom-in"
            >
              <GiMaterialsScience className="expandedicon mt-3 mb-3" />
            </Col>
            <Col
              sm={12}
              md={8}
              className="text-white mb-5"
              data-aos="flip-left"
            >
              <h5 className="text-info">Governance of Material</h5>
              What is the context of your business model? You're looking for
              news, blogs, and articles that conveys the story behind your
              products. You'll need an easy-to-use content management system
              that would share these stories  effortlessly. You must be able to
              simply add and update new pages at any moment. To stimulate
              interest and produce assets that can be readily shared on social
              media, make sure you can add photographs, connect to product
              sites, establish SEO components, and archive articles in a
              user-friendly manner.
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ order: "second" }}
              sm={{ order: "first" }}
              md={8}
              className="text-white mb-5"
              data-aos="flip-right"
            >
              <h5 className="text-info">
                Sales promotions and Discounting Resources
              </h5>
              Everyone enjoys a good bargain! A simple method for producing and
              maintaining discount codes should be available on your website.
              You need to be able to quickly specify the conditions and
              constraints that determine how consumers locate and use
              promotional discounts, whether it's a percentage off or a
              limited-time price discount.
            </Col>
            <Col
              xs={{ order: "first" }}
              sm={{ order: "second" }}
              md={4}
              className="text-center text-danger"
              data-aos="zoom-in"
            >
              <MdOutlinePriceChange className="expandedicon mt-3 mb-3" />
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              md={4}
              className="text-center text-white"
              data-aos="zoom-in"
            >
              <MdOutlineSettingsSuggest className="expandedicon mt-3 mb-3" />
            </Col>
            <Col
              sm={12}
              md={8}
              className="text-white mb-5"
              data-aos="flip-left"
            >
              <h5 className="text-info">Recommendations And Similar Items</h5>
              Consumers prefer browsing and sharing, so make it simple for them.
              Use pattern recognition to display users on your site goods that
              are similar to the ones they're looking at. Predictive text fill
              makes it simple for consumers to find particular goods they desire
              by auto-filling search bar options. Pay special attention to
              promotional and seasonal products. Include a "sharing" feature
              that allows users to email goods they like to others.
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ order: "second" }}
              sm={{ order: "first" }}
              md={8}
              className="text-white mb-5"
              data-aos="flip-right"
            >
              <h5 className="text-info">Filtration of Products</h5>
              Allow your clients to customize their buying experience by
              allowing them to browse category pages. They may focus on the
              goods they truly want to view if they can sort by price,
              popularity, or relevancy. Remember to employ a "quick preview"
              function so customers don't have to go through to a product page
              unless they're genuinely interested.
            </Col>
            <Col
              xs={{ order: "first" }}
              sm={{ order: "second" }}
              md={4}
              className="text-center"
              data-aos="zoom-in"
            >
              <FcFilledFilter className="expandedicon mt-3 mb-3" />
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              md={4}
              className="text-center text-info"
              data-aos="zoom-in"
            >
              <HiOutlineDocumentReport className="expandedicon mt-3 mb-3" />
            </Col>
            <Col
              sm={12}
              md={8}
              className="text-white mb-5"
              data-aos="flip-left"
            >
              <h5 className="text-info">Tools for Accounting</h5>
              Consumers prefer browsing and sharing, so make it simple for them.
              Use pattern recognition to display users on your site goods that
              are similar to the ones they're looking at. Predictive text fill
              makes it simple for consumers to find particular goods they desire
              by auto-filling search bar options. Pay special attention to
              promotional and seasonal products. Include a "sharing" feature
              that allows users to email goods they like to others.
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ order: "second" }}
              sm={{ order: "first" }}
              md={8}
              className="text-white mb-5"
              data-aos="flip-right"
            >
              <h5 className="text-info">Integration of Email Marketing</h5>
              Create a mailing list and you'll be well on your way to fostering
              long-term brand loyalty. We'll walk you through the process of
              setting up email notifications for order status and delivery
              information. We'll also help you set up email marketing templates,
              establish a subscriber list, configure your email server, and use
              segmented, targeted email campaigns to take your e-commerce
              marketing to the next level.
            </Col>
            <Col
              xs={{ order: "first" }}
              sm={{ order: "second" }}
              md={4}
              className="text-center text-warning"
              data-aos="zoom-in"
            >
              <SiMinutemailer className="expandedicon mt-3 mb-3" />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4} className="text-center" data-aos="zoom-in">
              <FcShop className="expandedicon mt-3 mb-3" />
            </Col>
            <Col
              sm={12}
              md={8}
              className="text-white mb-5"
              data-aos="flip-left"
            >
              <h5 className="text-info">Interactive Shopping</h5>
              Make it simple to add products to the "shopping cart"! The
              consumer should see a cart dynamically populate with things, and
              at the conclusion of the process, user should be able to store an
              item for later rather than being forced to remove it totally if
              they don't want it today. Display trust symbols like as VeriSign
              or McAfee badges to show that your checkout process has been
              validated and is safe; this will alleviate any concerns that
              potential consumers may have about purchasing with you.
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ order: "last" }}
              sm={{ order: "first" }}
              md={8}
              className="text-white mb-5 order-sm-12"
              data-aos="flip-right"
            >
              <h5 className="text-info">
                Several Payment and Shipping Options
              </h5>
              You must instill trust in your payment method in order to do
              business online. Provide a number of payment options. A clean
              payment confirmation page will make the checkout process go
              smoothly (including an email confirming receipt of payment).
              Before consumers begin the checkout process, make sure they can
              compute shipping charges directly from their shopping carts. Give
              them a variety of delivery alternatives. Some clients like to get
              their things straight quickly, while others are content to wait.
              Finally, give tracking information to your consumers to put their
              minds at ease throughout the final phase of the purchase.
            </Col>
            <Col
              xs={{ order: "first" }}
              sm={{ order: "second" }}
              md={4}
              className="text-center order-sm-1"
              data-aos="zoom-in"
            >
              <FcFilledFilter className="expandedicon mt-3 mb-3" />
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <br />
        <br />
      </Container>
    </Layout>
  )
}

export default ecommerce
